
import { defineComponent, onMounted, ref } from 'vue'
import { getActiveList, getDict } from '@/api/home'
interface dictType {
  label: string
  id: string
}
export default defineComponent({
  setup() {
    const tabList = ref()
    const params = {
      type: '',
      page: 1,
      size: 6
    }
    const hotParams = {
      type: 4,
      page: 1,
      size: 6
    }
    const newsTotal = ref(null)
    const newsList = ref(Array)
    const hotList = ref([])
    const active = ref(0)
    const drawer = ref(false)
    onMounted(async () => {
      getActiveListFucn()
      getDictFunc()
    })
    const getActiveListFucn = async () => {
      const data = await getActiveList(params)
      newsList.value = data.data.records
      newsTotal.value = data.data.total
    }
    const getDictFunc = async () => {
      const data = await getDict('NEWS_TYPE')
      console.log(data)
      const list = data.data
      list.unshift({
        label: '全部',
        value: ''
      })
      list.push({
        label: '活动',
        value: 'ht'
      })
      const hotValue = list.filter((e: dictType) => e.label === '热门')
      console.log('hotValue', hotValue)
      hotParams.type = hotValue[0].value
      const data1 = await getActiveList(hotParams)
      console.log('data1', data1)
      hotList.value = data1.data.records
      const index = list.findIndex((e: dictType) => e.label === '热门')
      console.log(index)
      list.splice(index, 1)
      tabList.value = list
    }
    const onCurrentChange = (e: number) => {
      params.page = e
      getActiveListFucn()
      document.body.scrollTop = document.documentElement.scrollTop = 0
    }
    const changeTab = (e: string, i: number) => {
      active.value = i
      params.page = 1
      params.type = e
      getActiveListFucn()
      drawer.value = true
    }
    return {
      active,
      tabList,
      changeTab,
      newsList,
      hotList,
      params: ref(params),
      newsTotal,
      onCurrentChange,
      drawer
    }
  },
  methods: {
    next(id: number) {
      if (this.params.type === 'ht') {
        this.$router.push(`/site/detail?id=${id}`)
      } else {
        this.$router.push(`/active/detail?id=${id}`)
      }
    }
  }
})
